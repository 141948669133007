import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(VSimpleTable, [
        _c("thead", [
          _c("tr", [
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$t("name"))),
            ]),
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$t("email"))),
            ]),
            _vm.userType != _vm.userTypeEnum.Banker
              ? _c("th", { staticClass: "text-center" }, [
                  _vm._v(" " + _vm._s(_vm.$tc("banker", 1)) + " "),
                ])
              : _vm._e(),
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$t("invested"))),
            ]),
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$t("pending_allocations"))),
            ]),
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$t("last_login"))),
            ]),
            _c("th", { staticClass: "text-center" }, [
              _vm._v(" " + _vm._s(_vm.$t("options")) + " "),
            ]),
          ]),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.Users, function (user, index) {
            return _c("tr", { key: index }, [
              _c("td", { staticClass: "text-center" }, [
                _vm._v(_vm._s(user.name + " " + user.lastName)),
              ]),
              _c("td", { staticClass: "text-center" }, [
                _vm._v(_vm._s(user.email)),
              ]),
              _vm.userType != _vm.userTypeEnum.Banker
                ? _c("td", { staticClass: "text-center" }, [
                    _vm._v(" " + _vm._s(_vm.gs.show_name(user.banker)) + " "),
                  ])
                : _vm._e(),
              _c("td", { staticClass: "text-center" }, [
                user.totalInvested && user.totalInvested.length > 0
                  ? _c(
                      "div",
                      _vm._l(user.totalInvested, function (totalInvested, i) {
                        return _c("span", { key: i, staticClass: "block" }, [
                          _vm._v(
                            _vm._s(
                              _vm.formatCurrency(
                                totalInvested.currency,
                                totalInvested.value
                              )
                            )
                          ),
                        ])
                      }),
                      0
                    )
                  : _c("span", [_vm._v("-")]),
              ]),
              _c("td", { staticClass: "text-center" }, [
                user.totalAllocated && user.totalAllocated.length > 0
                  ? _c(
                      "div",
                      _vm._l(user.totalAllocated, function (totalAllocated, i) {
                        return _c("span", { key: i, staticClass: "block" }, [
                          _vm._v(
                            _vm._s(
                              _vm.formatCurrency(
                                totalAllocated.currency,
                                totalAllocated.value
                              )
                            )
                          ),
                        ])
                      }),
                      0
                    )
                  : _c("span", [_vm._v("-")]),
              ]),
              _c("td", { staticClass: "text-center" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      user.lastLoginDate
                        ? _vm.gs.convert_date(user.lastLoginDate, true)
                        : "-"
                    ) +
                    " "
                ),
              ]),
              _c(
                "td",
                { staticClass: "text-center" },
                [
                  _c(
                    VBtn,
                    {
                      attrs: { small: "", color: "secondary" },
                      on: {
                        click: function ($event) {
                          return _vm.user_info(user)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("see_more")) + " ")]
                  ),
                  _c(
                    VTooltip,
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _vm.userType == _vm.userTypeEnum.Partner
                                  ? _c(
                                      VBtn,
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "px-2 ml-1 secondary",
                                            attrs: {
                                              "min-width": "0",
                                              small: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.open_block_dialog(
                                                  user
                                                )
                                              },
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        !user.partnerBlocked
                                          ? _c(VIcon, [
                                              _vm._v("mdi-block-helper"),
                                            ])
                                          : _c(VIcon, [
                                              _vm._v("mdi-lock-open"),
                                            ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("block_user")))])]
                  ),
                ],
                1
              ),
            ])
          }),
          0
        ),
      ]),
      _vm.block_dialog
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.block_dialog,
                callback: function ($$v) {
                  _vm.block_dialog = $$v
                },
                expression: "block_dialog",
              },
            },
            [
              _c("DeleteConfirmationModal", {
                attrs: {
                  obj: _vm.block_obj,
                  name: _vm.block_obj.name + " " + _vm.block_obj.lastName,
                  CustomMessage: _vm.block_obj.partnerBlocked
                    ? _vm.$tc("block_confirmation", 2)
                    : _vm.$tc("block_confirmation", 1),
                },
                on: {
                  close: function ($event) {
                    _vm.block_dialog = false
                  },
                  delete: _vm.block_user,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }